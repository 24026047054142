/* You can add global styles to this file, and also import other style files */
.a4-paper {
    position: relative;
    .cancel {
        position: absolute;
        z-index: 100000;
        top: -24vh;
        left: 0;
        width: 100%;
        opacity: 0.4;
    }
}

.min-100 {
    min-width: 100px;
}
.min-65 {
    min-width: 65px;
}
.min-50 {
    min-width: 50px;
}
